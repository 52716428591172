<template>
  <div class="row">

    <div class="col-md-12 card p-0">
      <div class="filter-group-bill">
        <div class="row">
          <p class="col-3 filter-col">
            <my-select
              class="full-width"
              :attribute="bill_races"
              placeholder="Chọn race (bắt buộc)"
              v-model="form.race_id"
              :class="errors.has('form.race_id') ? 'border-danger':''"
              v-validate="'required'"
              data-vv-name="form.race_id"
              data-vv-as="Cuộc đua">
            </my-select>
            <span class="text-danger" v-if="errors.has('form.race_id')">{{ errors.first('form.race_id') }}</span>
          </p>
          <p class="col-3 filter-col">
            <el-select v-model="form.user_race_status" placeholder="Trạng thái tham gia" class="full-width" clearable>
              <el-option label="Hoàn thành" value="completed"></el-option>
              <el-option label="Chưa hoàn thành" value="joined"></el-option>
            </el-select>
          </p>
          <p class="col-3 filter-col">
            <el-select v-model="form.location" placeholder="Khu vực" class="full-width" clearable>
              <el-option label="Hồ Chí Minh" value="Hồ Chí Minh"></el-option>
              <el-option label="Hà Nội" value="Hà Nội"></el-option>
              <el-option label="Khác" value="other"></el-option>
            </el-select>
          </p>
          <div class="col-3 filter-col">
            <el-button class="full-width" type="primary" icon="el-icon-search" @click="loadOrders">
              Lọc đơn hàng
            </el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-3 filter-col">
            <el-input v-model="form.order_id" placeholder="Mã đơn hàng"></el-input>
          </div>
          <div class="col-3 filter-col">
            <el-input v-model="form.user_id" placeholder="Mã người dùng"></el-input>
          </div>
          <div class="col-3 filter-col">
            <el-select v-model="form.shipping_status" placeholder="Trạng thái ship hàng" class="full-width" clearable>
              <el-option label="Chưa ship" value="0"></el-option>
              <el-option label="Đã ship" value="1"></el-option>
            </el-select>
          </div>
          <div class="col-3 filter-col" v-if="count_orders">
            <el-button class="full-width" type="warning" icon="el-icon-download" @click="exportOrders">
              Export đơn hàng
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 card p-0 d-none" v-if="count_orders">
      <div class="card-body">
        <p>Số lượng đơn hàng: <b>{{ count_orders }}</b></p>
        <div class="row">
          <div class="col-3">
            <el-select class="full-width" placeholder="Chọn nhà vận chuyển" v-model="shipping_status">
              <el-option
                v-for="(item, index) in bill_transporters"
                :key="index"
                :value="(index - 0)"
                :label="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-3">
            <el-input
              placeholder="Mã vận đơn"
              v-model="shipping_code"
            ></el-input>
          </div>
          <div class="col-3">
            <el-date-picker
              v-model="shipped_at"
              type="datetime"
              class="full-width"
              placeholder="Ngày giao">
            </el-date-picker>
          </div>
          <div class="col-3 filter-col">
            <el-button class="full-width" type="info" icon="el-icon-success" @click="updateTransporters">
              Cập nhật tất cả đơn hàng
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 card p-0" v-if="count_orders">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions"
            :actionsTable="actionsTable" />
        </div>
      </div>
    </div>
    <el-dialog title="Giao hàng" :visible.sync="dialogUpdateShipping" width="50%">
      <div class="row">
        <div class="col-4">
            <el-select class="full-width" placeholder="Chọn nhà vận chuyển" v-model="order_detail.shipping_status">
              <el-option
                v-for="(item, index) in bill_transporters"
                :key="index"
                :value="(index - 0)"
                :label="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-4">
            <el-input
              placeholder="Mã vận đơn"
              v-model="order_detail.shipping_code">
            </el-input>
          </div>
          <div class="col-4">
            <el-date-picker
              class="full-width"
              v-model="order_detail.shipped_at"
              type="datetime"
              placeholder="Ngày giao">
            </el-date-picker>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="dialogUpdateShipping = false">Hủy</el-button>
        <el-button size="sm" type="primary" @click="updateShippingOrderLine">Cập nhật</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Import file" :visible.sync="dialogImportFile" width="50%">
      <div class="row">
        <div class="col-12 text-center">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleUploadChange"
            :on-remove="handleRemoveUpload"
            accept=".xlsx,.xls"
            :auto-upload="false"
            :multiple="false"
            :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Chọn file</div>
            <div class="el-upload__tip" slot="tip">Hỗ trợ file xls, xlsx</div>
          </el-upload>
          <div>
            <a href="https://irace.vn/races/files/shipping_code_template.xlsx" target="_blank">Tải file mẫu</a>
          </div>
          <div class="import-status" v-if="showImportStatus">
            <el-tag type="primary">Tổng: {{ import_total }}</el-tag>
            <el-tag type="success">Thành công: {{ import_success }}</el-tag>
            <el-tag type="danger">Thất bại: {{ import_total - import_success ? import_total - import_success : 0 }}</el-tag>
            <div style="margin-top: 10px;">
              <el-tag v-if="import_errors.length" v-for="(item, index) of import_errors" :key="index" type="warning" size="small">{{ item }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="closeModalImport">Hủy</el-button>
        <el-button size="sm" type="primary" @click="submitUpload">Import</el-button>
      </span>
    </el-dialog>

    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
  import { Message, Dialog, Button, Input, Select, Option, DatePicker, Upload, Progress, Tag } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue';
  import billSchemas from './bill-schemas';
  import dtHelper from 'src/helpers/datatable';
  import MySelect from 'src/components/UIComponents/Select';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      MyTable,
      Message,
      MySelect,
      Loading,
      ElDialog: Dialog,
      ElButton: Button,
      ElInput: Input,
      ElSelect: Select,
      ElOption: Option,
      ElUpload: Upload,
      ElProgress: Progress,
      ElTag: Tag,
      ElDatePicker: DatePicker
    },
    computed: {
      bill_races() {
        let data = this.$store.state.bill_races;
        let obj = {};
        obj.options = data;
        return obj;
      },
      bill_transporters() {
        let data = this.$store.state.bill_transporters;
        return data;
      },

      orders() {
        let rows = this.$store.state.bills.data || [];
        return rows;
      }
    },
    data() {
      return {
        columnDefs: dtHelper.buildColumDefs(billSchemas),
        actionsTable: [],
        // actions: [
        //   {
        //     type: 'primary',
        //     icon: 'el-icon-view',
        //     callback: this.showModalUpdate
        //   }
        // ],
        actions: [],
        isLoading: false,
        count_orders: 0,
        dialogUpdateShipping: false,
        dialogImportFile: false,
        showImportStatus: false,
        form: {
          race_id: '',
          location: '',
          user_race_status: '',
          shipping_status: '',
          order_id: ''
        },
        shipping_status: '',
        shipping_code: '',
        shipped_at: '',
        order_detail: {},
        fileList: [],
        import_total: 0,
        import_success: 0,
        import_errors: []
      }
    },
    methods: {

      showModalImportFile() {
        this.dialogImportFile = true;
        this.showImportStatus = false;
      },

      closeModalImport() {
        this.dialogImportFile = false;
        this.showImportStatus = false;
        this.fileList = [];
      },

      handleUploadChange(file, fileList) {
        this.fileList = fileList.slice(-1);
      },

      handleRemoveUpload(file, fileList) {
        this.fileList = [];
      },

      submitUpload() {
        this.showImportStatus = false;
        this.import_success = 0;
        this.import_total = 0;
        this.import_errors = [];

        if (this.fileList.length == 0) {
          Message({
            type: 'error',
            message: 'Vui lòng chọn file'
          });
          return false;
        }
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file_import', this.fileList[0].raw);
        this.$store.dispatch('importShippingStatus', formData).then(res => {
          this.showImportStatus = true;
          this.import_total = res.total;
          this.import_success = res.save;
          this.import_errors = res.errors;
          this.isLoading = false;
        });
      },

      showModalUpdate(index, row) {
        this.order_detail = Object.assign({}, row);
        this.dialogUpdateShipping = true;
      },

      updateShippingOrderLine() {
        this.isLoading = true;
        this.$store.dispatch('updateShippingOrderLine', this.order_detail).then(res => {
          this.dialogUpdateShipping = false;
          Message({
            type: 'success',
            message: 'Cập nhật thành công'
          });
          this.loadOrders();
          this.isLoading = false;
        });
      },

      loadOrders() {
        if (!this.form.race_id) {
          return Message({
            type: 'error',
            message: 'Chưa chọn race'
          });
        }
        this.isLoading = true;
        this.$store.dispatch('fetchAllBills', this.form).then(res => {
          setTimeout(_ => {
            this.isLoading = false;
          }, 500)
        });
      },

      exportOrders() {
        this.isLoading = true;
        this.$store.dispatch('exportShippingStatus', { orders: this.orders }).then(res => {
          location.href = res.url;
          setTimeout(_ => {
            this.isLoading = false;
          }, 500)
        });
      },

      updateTransporters() {
        if (this.shipping_status == '') {
          return Message({
            type: 'error',
            message: 'Chưa chọn nhà vận chuyển'
          });
        }

        if (this.shipping_code == '') {
          return Message({
            type: 'error',
            message: 'Chưa nhập mã code'
          });
        }

        if (this.shipped_at == '') {
          return Message({
            type: 'error',
            message: 'Chưa nhập ngày giao hàng'
          });
        }

        let order_ids = this.orders.map(x => x.order_line_id);
        let obj = {
          order_ids: order_ids,
          shipping_status: this.shipping_status,
          shipped_at: this.shipped_at,
          code: this.shipping_code
        }
        this.isLoading = true;
        this.$store.dispatch('updateTransporters', obj).then((res) => {
          if (res.success) {
            Message({
              type: 'success',
              message: 'Cập nhật thành công'
            });
            this.loadOrders();
            this.isLoading = false;
          }
        });
      }
    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchBills');
      this.$store.dispatch('setPageTitle', 'Quản lý mã vận đơn');
      this.$store.dispatch('setCurrentFilterUsers', []);
      this.$store.dispatch('setCurrentActions', []);
    },
    watch: {
      orders(newVal) {
       if (newVal)  {
         this.count_orders = newVal.length;
       }
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss" scoped>
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .import-status {
    width: 60%;
    margin: 20px auto 0;
  }

</style>
