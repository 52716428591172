var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-12 card p-0" }, [
        _c("div", { staticClass: "filter-group-bill" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "p",
              { staticClass: "col-3 filter-col" },
              [
                _c("my-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "full-width",
                  class: _vm.errors.has("form.race_id") ? "border-danger" : "",
                  attrs: {
                    attribute: _vm.bill_races,
                    placeholder: "Chọn race (bắt buộc)",
                    "data-vv-name": "form.race_id",
                    "data-vv-as": "Cuộc đua",
                  },
                  model: {
                    value: _vm.form.race_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "race_id", $$v)
                    },
                    expression: "form.race_id",
                  },
                }),
                _vm.errors.has("form.race_id")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("form.race_id"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "col-3 filter-col" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: {
                      placeholder: "Trạng thái tham gia",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.user_race_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "user_race_status", $$v)
                      },
                      expression: "form.user_race_status",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Hoàn thành", value: "completed" },
                    }),
                    _c("el-option", {
                      attrs: { label: "Chưa hoàn thành", value: "joined" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "col-3 filter-col" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Khu vực", clearable: "" },
                    model: {
                      value: _vm.form.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "location", $$v)
                      },
                      expression: "form.location",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Hồ Chí Minh", value: "Hồ Chí Minh" },
                    }),
                    _c("el-option", {
                      attrs: { label: "Hà Nội", value: "Hà Nội" },
                    }),
                    _c("el-option", {
                      attrs: { label: "Khác", value: "other" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3 filter-col" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "full-width",
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.loadOrders },
                  },
                  [_vm._v("\n            Lọc đơn hàng\n          ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-3 filter-col" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Mã đơn hàng" },
                  model: {
                    value: _vm.form.order_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "order_id", $$v)
                    },
                    expression: "form.order_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3 filter-col" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Mã người dùng" },
                  model: {
                    value: _vm.form.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_id", $$v)
                    },
                    expression: "form.user_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3 filter-col" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: {
                      placeholder: "Trạng thái ship hàng",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.shipping_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shipping_status", $$v)
                      },
                      expression: "form.shipping_status",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Chưa ship", value: "0" },
                    }),
                    _c("el-option", {
                      attrs: { label: "Đã ship", value: "1" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.count_orders
              ? _c(
                  "div",
                  { staticClass: "col-3 filter-col" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "full-width",
                        attrs: { type: "warning", icon: "el-icon-download" },
                        on: { click: _vm.exportOrders },
                      },
                      [_vm._v("\n            Export đơn hàng\n          ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.count_orders
        ? _c("div", { staticClass: "col-md-12 card p-0 d-none" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("p", [
                _vm._v("Số lượng đơn hàng: "),
                _c("b", [_vm._v(_vm._s(_vm.count_orders))]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "full-width",
                        attrs: { placeholder: "Chọn nhà vận chuyển" },
                        model: {
                          value: _vm.shipping_status,
                          callback: function ($$v) {
                            _vm.shipping_status = $$v
                          },
                          expression: "shipping_status",
                        },
                      },
                      _vm._l(_vm.bill_transporters, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { value: index - 0, label: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Mã vận đơn" },
                      model: {
                        value: _vm.shipping_code,
                        callback: function ($$v) {
                          _vm.shipping_code = $$v
                        },
                        expression: "shipping_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("el-date-picker", {
                      staticClass: "full-width",
                      attrs: { type: "datetime", placeholder: "Ngày giao" },
                      model: {
                        value: _vm.shipped_at,
                        callback: function ($$v) {
                          _vm.shipped_at = $$v
                        },
                        expression: "shipped_at",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3 filter-col" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "full-width",
                        attrs: { type: "info", icon: "el-icon-success" },
                        on: { click: _vm.updateTransporters },
                      },
                      [
                        _vm._v(
                          "\n            Cập nhật tất cả đơn hàng\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.count_orders
        ? _c("div", { staticClass: "col-md-12 card p-0" }, [
            _c("div", { staticClass: "card-body row p-0" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("my-table", {
                    attrs: {
                      columnDefs: _vm.columnDefs,
                      "data-rows": _vm.orders,
                      actions: _vm.actions,
                      actionsTable: _vm.actionsTable,
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Giao hàng",
            visible: _vm.dialogUpdateShipping,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUpdateShipping = $event
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Chọn nhà vận chuyển" },
                    model: {
                      value: _vm.order_detail.shipping_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.order_detail, "shipping_status", $$v)
                      },
                      expression: "order_detail.shipping_status",
                    },
                  },
                  _vm._l(_vm.bill_transporters, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: index - 0, label: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Mã vận đơn" },
                  model: {
                    value: _vm.order_detail.shipping_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.order_detail, "shipping_code", $$v)
                    },
                    expression: "order_detail.shipping_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("el-date-picker", {
                  staticClass: "full-width",
                  attrs: { type: "datetime", placeholder: "Ngày giao" },
                  model: {
                    value: _vm.order_detail.shipped_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.order_detail, "shipped_at", $$v)
                    },
                    expression: "order_detail.shipped_at",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      _vm.dialogUpdateShipping = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "sm", type: "primary" },
                  on: { click: _vm.updateShippingOrderLine },
                },
                [_vm._v("Cập nhật")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Import file",
            visible: _vm.dialogImportFile,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImportFile = $event
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      drag: "",
                      action: "",
                      "on-change": _vm.handleUploadChange,
                      "on-remove": _vm.handleRemoveUpload,
                      accept: ".xlsx,.xls",
                      "auto-upload": false,
                      multiple: false,
                      "file-list": _vm.fileList,
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("Chọn file"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("Hỗ trợ file xls, xlsx")]
                    ),
                  ]
                ),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://irace.vn/races/files/shipping_code_template.xlsx",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Tải file mẫu")]
                  ),
                ]),
                _vm.showImportStatus
                  ? _c(
                      "div",
                      { staticClass: "import-status" },
                      [
                        _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("Tổng: " + _vm._s(_vm.import_total)),
                        ]),
                        _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("Thành công: " + _vm._s(_vm.import_success)),
                        ]),
                        _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(
                            "Thất bại: " +
                              _vm._s(
                                _vm.import_total - _vm.import_success
                                  ? _vm.import_total - _vm.import_success
                                  : 0
                              )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          _vm._l(_vm.import_errors, function (item, index) {
                            return _vm.import_errors.length
                              ? _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    attrs: { type: "warning", size: "small" },
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              : _vm._e()
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "sm" }, on: { click: _vm.closeModalImport } },
                [_vm._v("Hủy")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "sm", type: "primary" },
                  on: { click: _vm.submitUpload },
                },
                [_vm._v("Import")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }